import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from 'fe-design-base/organisms/Dialog';
import Immutable from 'immutable';

import { getHoursProvidedConstants } from 'selectors/session';

import { cxHelpers } from 'util/className';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useExtendUx, useTrackUxOnMount } from 'util/uxEvents';

import InstagramSharing from '../InstagramSharing/InstagramSharing';
import SocialSharing from '../SocialSharing';

const { cx } = cxHelpers('SocialSharingDialog');

interface SocialSharingDialogProps {
  onClose: () => void;
  hoursProvidedConstants: Immutable.Map<string, string>;
}

const COMPONENT_MAPPING = {
  socialSharing: SocialSharing,
  instagramSharing: InstagramSharing,
};

export const SocialSharingDialog = ({
  onClose,
  hoursProvidedConstants,
}: SocialSharingDialogProps) => {
  const [currentView, setCurrentView] = useState<
    'socialSharing' | 'instagramSharing'
  >('socialSharing');
  const extendUx = useExtendUx();

  extendUx({ currentView });

  const CurrentComponent = COMPONENT_MAPPING[currentView];

  useTrackUxOnMount(EVENT_ACTIONS.DIALOG_VIEWED, TRACK_ACTION_TYPES.VIEW);

  const handleClickFacebookShare = useCallback(() => {
    const imageUrl = hoursProvidedConstants.get('image_url');
    const encodedImageUrl = encodeURIComponent(imageUrl);
    const hashTag = hoursProvidedConstants.get('fb_hash_tag');

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodedImageUrl}&hashtag=${hashTag}`,
      '_blank'
    );
  }, [hoursProvidedConstants]);

  const handleClickInstagramShare = useCallback(() => {
    setCurrentView('instagramSharing');
  }, []);

  const handleViewSocialSharing = useCallback(() => {
    setCurrentView('socialSharing');
  }, []);

  return (
    <Dialog
      open
      className={cx()}
      onClose={onClose}
      uxElement="hours provided social sharing dialog"
    >
      {() => (
        <>
          <CurrentComponent
            handleViewSocialSharing={handleViewSocialSharing}
            handleClickFacebookShare={handleClickFacebookShare}
            handleClickInstagramShare={handleClickInstagramShare}
            googleDriveUrl={hoursProvidedConstants.get('google_drive_url')}
          />
        </>
      )}
    </Dialog>
  );
};

export default connect(state => ({
  hoursProvidedConstants: getHoursProvidedConstants(state),
}))(SocialSharingDialog);
