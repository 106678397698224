import './LeftPanelReferralBanner.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import HomebaseLogo from 'fe-design-base/assets/Logo/logos/HomebaseLogo';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import { ButtonTarget } from 'fe-design-base/enums';
import Button from 'fe-design-base/molecules/Button';
import isNumber from 'lodash/isNumber';

import { preSignupEnrollInExperiment } from 'features/signUp/actions';
import { getProductGrowthReferralSignInExperimentValue } from 'features/signUp/selectors';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { getTemporarySessionId } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useExtendUx, useTrackUx, withUxRoot } from 'util/uxEvents';
export interface LeftPanelReferralBannerProps {
  productGrowthReferralSignInExperimentValue?: number;
  preSignupExperimentEnrollment: ({
    identifier,
    experimentName,
  }: {
    identifier: string;
    experimentName: string;
  }) => void;
}

const EXPERIMENT_MAPPING: Record<number, Record<string, string>> = {
  0: {
    category: '100_dollars_bonus',
    link: 'https://form.typeform.com/to/oPUWXUxw',
  },
  1: {
    category: 'one_month_free',
    link: 'https://form.typeform.com/to/XvrGpsV2',
  },
};

const { cx } = cxHelpers('LeftPanelReferralBanner');

export const LeftPanelReferralBanner = ({
  productGrowthReferralSignInExperimentValue,
  preSignupExperimentEnrollment,
}: LeftPanelReferralBannerProps) => {
  const trackUx = useTrackUx();
  const extendUxEvent = useExtendUx();

  useEffect(() => {
    if (isNumber(productGrowthReferralSignInExperimentValue)) return;

    preSignupExperimentEnrollment({
      identifier: getTemporarySessionId(),
      experimentName: 'productgrowth_referrals_sign_in',
    });
  }, [
    productGrowthReferralSignInExperimentValue,
    preSignupExperimentEnrollment,
  ]);

  useEffect(() => {
    if (isNumber(productGrowthReferralSignInExperimentValue)) {
      trackUx(EVENT_ACTIONS.SECTION_VIEWED, TRACK_ACTION_TYPES.VIEW);
    }
  }, [productGrowthReferralSignInExperimentValue, trackUx]);

  extendUxEvent({
    component: 'LeftPanelReferralBanner',
    ...(isNumber(productGrowthReferralSignInExperimentValue) && {
      productgrowth_referrals_sign_in:
        productGrowthReferralSignInExperimentValue,
    }),
  });

  const bannerDetails = isNumber(productGrowthReferralSignInExperimentValue)
    ? EXPERIMENT_MAPPING[productGrowthReferralSignInExperimentValue]
    : {};

  const { link: experimentLink, category: experimentCategory } = bannerDetails;

  return (
    <Box className={cx()} h="100%" color="mono0">
      <Box p={16} ml={32} mt={32} h={27} w={168}>
        <HomebaseLogo />
      </Box>

      <Box alignItemsEnd ph={48} maxw={470}>
        <Box>
          <Box mb={8}>
            <Text
              variant="heading1"
              i18n={`splash_page.referral_banner.${experimentCategory}.header`}
            />
          </Box>

          <Box mb={24}>
            <Text
              i18n={`splash_page.referral_banner.${experimentCategory}.body`}
            />
          </Box>

          <Button
            type="button"
            href={experimentLink}
            target={ButtonTarget.Blank}
            uxElement="refer_a_business"
          >
            {toI18n('splash_page.referral_banner.refer_a_business')}
          </Button>
        </Box>
      </Box>

      <Box mt={75} ph={48}>
        <Box mb={8}>
          <img
            height="24"
            width="134"
            alt="Brooklyn tea"
            src={require('../images/brooklyn_tea.svg')}
          />
        </Box>

        <Text>Brooklyn, NY</Text>
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    productGrowthReferralSignInExperimentValue:
      getProductGrowthReferralSignInExperimentValue(state),
  }),
  {
    preSignupExperimentEnrollment: preSignupEnrollInExperiment,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.SIGN_IN,
    eventCategory: EVENT_CATEGORIES.SIGN_IN,
  })(LeftPanelReferralBanner)
);
