import { createSelector } from 'reselect';

import {
  getCompanyPayrollState,
  getCurrentLocation,
  getCurrentLocationTierId,
  getCurrentLocationTierName,
  getGhostPackagingManagePlanPageExperimentEnabled,
  getHasActiveTrialPeriod,
  getStarterKitPayrollPackage,
  getStarterPackageEnabled,
} from 'selectors/session';

import { selectIsSubscribedAnnually } from 'features/biller/selectors';
import {
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
} from 'features/enforcement/EnforcementBanner/selectors';
import { planCardCtaType } from 'features/managePlan/util';

import {
  selectHasMoreThan20Employees,
  selectIsCurrentLocationTierBasic,
} from '../enforcement/EnforcementBanner/selectors';
import { TIER_NAMES } from '../tiers/constants';

import { ACTIVE_PAYROLL } from './constants';

export const selectAllProducts = state => state.get('managePlan');

export const selectFeatures = state => state.get('managePlan').features;

export const selectIsSelectedBillingCycleAnnual = state =>
  state.get('managePlan').isSelectedBillingCycleAnnual;

export const selectLocationInNonPayingUnsecuredTrial = state =>
  state.getIn(['session', 'monetization', 'locationInNonPayingUnsecuredTrial']);

export const selectLocationHasActiveBaseGuidedRetrial = state =>
  state.getIn(['session', 'monetization', 'hasActiveBaseGuidedRetrial']);

export const selectLocationIsOnStarterPayrollPackage = createSelector(
  getStarterPackageEnabled,
  getStarterKitPayrollPackage,
  (starterPackageEnabled, starterKitPayrollPackage) =>
    starterPackageEnabled && Boolean(starterKitPayrollPackage)
);

export const selectGhostEmployeeExperimentsEnabled = createSelector(
  getGhostPackagingManagePlanPageExperimentEnabled,
  selectGhostEmployeeCapV1ExperimentEnabled,
  selectGhostEmployeeCapV2ExperimentEnabled,
  (
    ghostPackagingManagePlanPageExperimentEnabled,
    ghostEmployeeCapV1ExperimentEnabled,
    ghostEmployeeCapV2ExperimentEnabled
  ) =>
    ghostPackagingManagePlanPageExperimentEnabled ||
    ghostEmployeeCapV1ExperimentEnabled ||
    ghostEmployeeCapV2ExperimentEnabled
);

export const selectShouldShowEnforcementBottomSheet = createSelector(
  selectGhostEmployeeExperimentsEnabled,
  selectHasMoreThan20Employees,
  selectIsCurrentLocationTierBasic,
  (
    ghostEmployeeExperimentsEnabled,
    hasMoreThan20Employees,
    isCurrentLocationTierBasic
  ) =>
    ghostEmployeeExperimentsEnabled &&
    hasMoreThan20Employees &&
    !isCurrentLocationTierBasic
);

export const selectPlanCardCta = createSelector(
  (_, props) => props.tierId,
  (_, props) => props.tierName,
  getCurrentLocationTierId,
  selectIsSubscribedAnnually,
  selectIsSelectedBillingCycleAnnual,
  getHasActiveTrialPeriod,
  selectLocationIsOnStarterPayrollPackage,
  (
    tierId,
    tierName,
    currentLocationTierId,
    isSubscribedAnnually,
    isSelectedBillingCycleAnnual,
    hasActiveTrialPeriod,
    locationIsOnStarterPayrollPackage
  ) =>
    planCardCtaType({
      tierId,
      tierName,
      isSubscribedAnnually,
      hasActiveTrialPeriod,
      currentLocationTierId,
      isSelectedBillingCycleAnnual,
      locationIsOnStarterPayrollPackage,
    })
);

export const selectProducts = createSelector(
  selectAllProducts,
  selectIsSelectedBillingCycleAnnual,
  (products, isSelectedBillingCycleAnnual) => {
    const { monthlyProducts, annualProducts } = products;

    return isSelectedBillingCycleAnnual ? annualProducts : monthlyProducts;
  }
);

export const selectHasAnnualProducts = createSelector(
  selectAllProducts,
  ({ annualProducts }) => !!annualProducts.length
);

export const selectIsUSCustomer = createSelector(
  getCurrentLocation,
  currentLocation => currentLocation.get('country_code') === 'US'
);

export const selectShouldShowPlanCardsFirst = createSelector(
  getCurrentLocationTierName,
  getHasActiveTrialPeriod,
  getCompanyPayrollState,
  getStarterPackageEnabled,
  (
    currentLocationTierName,
    hasActiveTrialPeriod,
    companyPayrollState,
    starterPackageEnabled
  ) =>
    currentLocationTierName === TIER_NAMES.BASIC ||
    hasActiveTrialPeriod ||
    starterPackageEnabled ||
    (currentLocationTierName !== TIER_NAMES.BASIC &&
      companyPayrollState === ACTIVE_PAYROLL)
);

export const selectProductTipManager = state =>
  state.get('managePlan').tipManager;
export const selectProductTipManagerPrice = state =>
  state.get('managePlan').tipManager.price;
