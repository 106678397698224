import React, { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { Checkbox as MUICheckbox } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import CheckboxCheckedIcon from 'fe-design-base/assets/icons/checkbox/Checked';
import CheckboxIndeterminateIcon from 'fe-design-base/assets/icons/checkbox/Indeterminate';
import CheckboxUncheckedIcon from 'fe-design-base/assets/icons/checkbox/Unchecked';
import { BaseInputProps } from 'fe-design-base/baseTypes';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export interface CheckboxProps
  extends Omit<BaseInputProps, 'onFocus' | 'onBlur'> {
  checked?: boolean;
  indeterminate?: boolean;
  label?: string | ReactNode;
  onClick?: (checked: boolean) => void;
  uxElement?: string;
  error?: boolean;
  'aria-label'?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps & CxProps>(
  // Destructured cxEl here to prevent error when included in props
  (
    { cx, cxEl, disabled, label, name, onClick, uxElement, error, ...props },
    ref
  ) => {
    const trackUx = useTrackUx(
      useMemo(
        () => ({
          element: uxElement,
          field: label && typeof label === 'string' ? label : name,
        }),
        [label, name, uxElement]
      ) as any
    );
    const handleClick = useCallback(
      e => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e.target.checked);
        if (uxElement) {
          trackUx(EVENT_ACTIONS.CHECKBOX_CLICKED, TRACK_ACTION_TYPES.CLICK, {
            isChecked: e.target.checked,
          });
        }
      },
      [disabled, onClick, trackUx, uxElement]
    );
    return (
      <MUICheckbox
        aria-disabled={disabled}
        name={name}
        inputProps={{ 'aria-label': props['aria-label'] }}
        className={cx({ error })}
        inputRef={ref}
        disableRipple
        disableTouchRipple
        icon={<CheckboxUncheckedIcon />}
        onClick={handleClick}
        checkedIcon={<CheckboxCheckedIcon />}
        indeterminateIcon={<CheckboxIndeterminateIcon />}
        {...props}
      />
    );
  }
);

export default withCx<CheckboxProps>('FDBCheckbox')(Checkbox);
