import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getHoursProvidedExperimentEnabled } from 'selectors/session';

import HoursProvidedDialog from '../HoursProvidedDialog';

export interface DisplayHoursProvidedDialogFromUrlProps {
  hoursProvidedExperimentEnabled: boolean;
}

export const DisplayHoursProvidedDialogFromUrl = ({
  hoursProvidedExperimentEnabled,
}: DisplayHoursProvidedDialogFromUrlProps) => {
  const [openSharingDialog, setOpenSharingDialog] = useState(false);

  useEffect(() => {
    if (window.location.hash === '#hours-provided') {
      history.replaceState(null, '', ' ');

      if (hoursProvidedExperimentEnabled) setOpenSharingDialog(true);
    }
  }, [hoursProvidedExperimentEnabled]);

  const handleClose = useCallback(() => {
    setOpenSharingDialog(false);
  }, []);

  return (
    <>
      {openSharingDialog && (
        <HoursProvidedDialog fromUrl onClose={handleClose} />
      )}
    </>
  );
};

export default connect(state => ({
  hoursProvidedExperimentEnabled: getHoursProvidedExperimentEnabled(state),
}))(DisplayHoursProvidedDialogFromUrl);
