import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';
import {
  ApprovalState,
  BackgroundCheckState,
  CandidateApplicationInfo,
  CandidateCheckPresubmissionResponse,
  CandidateInfo,
  CandidateQuery,
  CompanyInfo,
  OwnerInfo,
  PanelStates,
  ProductsResponse,
} from './types';

const initialState: BackgroundCheckState = {
  approvalStatus: ApprovalState.Unapplied,
  currentPanelKey: 'zeroState',
  candidateQuery: {
    candidates: [],
    currentPage: 0,
    loading: true,
    error: '',
  },
  ownerInfo: {
    first_name: '',
    last_name: '',
    email: '',
    title: '',
    phone_number: '',
  },
  companyInfo: {
    companyLegalName: '',
    dbaTradeName: '',
    companyWebsite: '',
    companyType: '',
    phone: '',
    industry: '',
    fein: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    termsAccepted: false,
  },
  candidateInfo: {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    phone_number: '',
  },
  candidateCheckPresubmission: {
    candidate_check: undefined,
  },
  products: {
    products: [],
  },
};

const backgroundCheckSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setApprovalStatus(state, action: PayloadAction<ApprovalState>) {
      state.approvalStatus = action.payload;
    },
    setCurrentPanelKey(state, action: PayloadAction<PanelStates>) {
      state.currentPanelKey = action.payload;
    },
    setCandidateQuery(state, action: PayloadAction<CandidateQuery>) {
      state.candidateQuery = action.payload;
    },
    setCandidateQueryLoading(state, action: PayloadAction<boolean>) {
      state.candidateQuery.loading = action.payload;
    },
    setCandidateQueryError(state, action: PayloadAction<string>) {
      state.candidateQuery.error = action.payload;
    },
    setCandidateQueryCandidates(state, action: PayloadAction<CandidateInfo[]>) {
      state.candidateQuery.candidates = action.payload;
    },
    setCandidateQueryCurrentPage(state, action: PayloadAction<number>) {
      state.candidateQuery.currentPage = action.payload;
    },
    setOwnerInfo(state, action: PayloadAction<OwnerInfo>) {
      state.ownerInfo = action.payload;
    },
    setOwnerInfoField(
      state,
      action: PayloadAction<{ field: keyof OwnerInfo; value: string }>
    ) {
      state.ownerInfo[action.payload.field] = action.payload.value;
    },
    setCompanyInfo(state, action: PayloadAction<CompanyInfo>) {
      state.companyInfo = action.payload;
    },
    setCandidateInfo(state, action: PayloadAction<CandidateApplicationInfo>) {
      state.candidateInfo = action.payload;
    },
    setCandidateCheckPresubmission(
      state,
      action: PayloadAction<CandidateCheckPresubmissionResponse>
    ) {
      state.candidateCheckPresubmission = action.payload;
    },
    setProducts(state, action: PayloadAction<ProductsResponse>) {
      state.products = action.payload;
    },
  },
});

export const {
  setApprovalStatus,
  setCurrentPanelKey,
  setCandidateQuery,
  setCandidateQueryCandidates,
  setCandidateQueryCurrentPage,
  setCandidateQueryError,
  setCandidateQueryLoading,
  setOwnerInfo,
  setOwnerInfoField,
  setCompanyInfo,
  setCandidateInfo,
  setCandidateCheckPresubmission,
  setProducts,
} = backgroundCheckSlice.actions;

export { backgroundCheckSlice };

export const { reducer } = backgroundCheckSlice;
