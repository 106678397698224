import './Icon.scss';

import React, { PureComponent } from 'react';
import {
  faBolt,
  faChevronLeft,
  faClipboardList,
  faCloudRain,
  faCloudSnow,
  faCloudSun,
  faComment,
  faEnvelope,
  faExclamationCircle,
  faFileAlt,
  faFog,
  faLockAlt,
  faMobile,
  faPen,
  faPhoneAlt,
  faPlus,
  faQuestionCircle,
  faSun,
  faThunderstorm,
  faUnlock,
  faUserHeadset,
} from '@fortawesome/pro-light-svg-icons';
import { faClone } from '@fortawesome/pro-solid-svg-icons/faClone.js';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber, keys } from 'lodash';
import PropTypes from 'prop-types';
import { colors, dimensions } from 'theme/constants';

import cxHelpers from 'util/className';

const SolidCloneProIcon = props => (
  <FontAwesomeIcon icon={faClone} {...props} />
);

const LockAltLightOutlineIcon = props => (
  <FontAwesomeIcon icon={faLockAlt} {...props} />
);
const ChevronLeftLightIcon = props => (
  <FontAwesomeIcon icon={faChevronLeft} {...props} />
);

const ClipboardListIcon = props => (
  <FontAwesomeIcon icon={faClipboardList} {...props} />
);
const UnlockLightOutlineIcon = props => (
  <FontAwesomeIcon icon={faUnlock} {...props} />
);
const CommentOutlineIcon = props => (
  <FontAwesomeIcon icon={faComment} {...props} />
);
const EditPenIcon = props => <FontAwesomeIcon icon={faPen} {...props} />;
const PhoneAltOutlineIcon = props => (
  <FontAwesomeIcon icon={faPhoneAlt} {...props} />
);
const BoltOutlineIcon = props => <FontAwesomeIcon icon={faBolt} {...props} />;
const WrenchIcon = props => <FontAwesomeIcon icon={faWrench} {...props} />;
const MobileProIcon = props => <FontAwesomeIcon icon={faMobile} {...props} />;
const QuestionCircleLiteProIcon = props => (
  <FontAwesomeIcon icon={faQuestionCircle} {...props} />
);
const FileAltIcon = props => <FontAwesomeIcon icon={faFileAlt} {...props} />;
const EnvelopeProIcon = props => (
  <FontAwesomeIcon icon={faEnvelope} {...props} />
);

const BangLight = props => (
  <FontAwesomeIcon icon={faExclamationCircle} {...props} />
);

const CloudRainIcon = props => (
  <FontAwesomeIcon icon={faCloudRain} {...props} />
);

const SunIcon = props => <FontAwesomeIcon icon={faSun} {...props} />;

const CloudSnowIcon = props => (
  <FontAwesomeIcon icon={faCloudSnow} {...props} />
);

const CloudSunIcon = props => <FontAwesomeIcon icon={faCloudSun} {...props} />;

const FogIcon = props => <FontAwesomeIcon icon={faFog} {...props} />;

const ThunderstormIcon = props => (
  <FontAwesomeIcon icon={faThunderstorm} {...props} />
);

const UserHeadsetIcon = props => (
  <FontAwesomeIcon icon={faUserHeadset} {...props} />
);

const PlusLight = props => <FontAwesomeIcon icon={faPlus} {...props} />;

import AddCircle from './svgs/AddCircle';
import Applicants from './svgs/Applicants';
import Approve from './svgs/Approve';
import Bell from './svgs/Bell';
import Bowl from './svgs/Bowl';
import Calendar from './svgs/Calendar';
import CalendarCheck from './svgs/CalendarCheck';
import CalendarO from './svgs/CalendarO';
import CalendarV2 from './svgs/CalendarV2';
import CashRegister from './svgs/CashRegister';
import CheckCircle from './svgs/CheckCircle';
import CheckFilled from './svgs/CheckFilled';
import Checklist from './svgs/Checklist';
import ChefHat from './svgs/ChefHat';
import ChevronDownSlim from './svgs/ChevronDownSlim';
import ChevronRightSlim from './svgs/ChevronRightSlim';
import Circle from './svgs/Circle';
import CircleCheck from './svgs/CircleCheck';
import ClockIn from './svgs/ClockIn';
import ClockOut from './svgs/ClockOut';
import ClockOutCircle from './svgs/ClockOutCircle';
import CloseCircle from './svgs/CloseCircle';
import Coffee from './svgs/Coffee';
import CoffeeBreak from './svgs/CoffeeBreak';
import CoffeeBreakOut from './svgs/CoffeeBreakOut';
import ComputerBlue from './svgs/ComputerBlue';
import ContactBook from './svgs/ContactBook';
import Decline from './svgs/Decline';
import Edit from './svgs/Edit';
import Email from './svgs/Email.jsx';
import EmptyInterviewState from './svgs/EmptyInterviewState';
import EnvelopeBlue from './svgs/EnvelopeBlue';
import Eye from './svgs/Eye';
import FileExplorer from './svgs/FileExplorer';
import FindCover from './svgs/FindCover';
import ForkKnife from './svgs/ForkKnife';
import GenericRole from './svgs/GenericRole';
import Headphones from './svgs/Headphones';
import HeartAura from './svgs/HeartAura';
import Hired from './svgs/Hired';
import Interview from './svgs/Interview';
import Interviews from './svgs/Interviews';
import JobCardsIllustration from './svgs/JobCardsIllustration';
import Knife from './svgs/Knife';
import LinkBlue from './svgs/LinkBlue';
import LongArrowRight from './svgs/LongArrowRight';
import MapMarker from './svgs/MapMarker.jsx';
import Martini from './svgs/Martini';
import Message from './svgs/Message';
import Message2 from './svgs/Message2';
import MobileBlue from './svgs/MobileBlue';
import MoneyBillTransfer from './svgs/MoneyBillTransfer';
import Mug from './svgs/Mug';
import Notes from './svgs/Notes';
import NotSent from './svgs/NotSent';
import PalmTree from './svgs/PalmTree';
import Pen from './svgs/Pen';
import Phone from './svgs/Phone.jsx';
import Referral from './svgs/Referral';
import Restaurant from './svgs/Restaurant';
import Resume from './svgs/Resume.jsx';
import RocketCircle from './svgs/RocketCircle';
import RocketLaunch from './svgs/RocketLaunch';
import RocketSolid from './svgs/RocketSolid';
import Schedule from './svgs/Schedule';
import ScrollLeft from './svgs/ScrollLeft';
import ScrollRight from './svgs/ScrollRight';
import Send from './svgs/Send';
import Stars from './svgs/Stars';
import ThumbsDown from './svgs/ThumbsDown';
import ThumbsDownFilled from './svgs/ThumbsDownFilled';
import ThumbsDownSelected from './svgs/ThumbsDownSelected';
import ThumbsUp from './svgs/ThumbsUp';
import Timeclock from './svgs/Timeclock';
import TimeOff from './svgs/TimeOff';
import TradeShift from './svgs/TradeShift';
import UserO from './svgs/UserO';
import UserPlus from './svgs/UserPlus';
import XMark from './svgs/XMark';
import ZipRecruiterLogo from './svgs/ZipRecruiterLogo';

const ICON_TYPE_MAP = {
  print: require('react-icons/lib/fa/print'),
  search: require('react-icons/lib/fa/search'),
  close: require('react-icons/lib/fa/times-circle'),
  closeSimple: require('react-icons/lib/fa/close'),
  mdClose: require('react-icons/lib/md/close'),
  bang: require('react-icons/lib/fa/exclamation-circle'),
  warning: require('react-icons/lib/fa/exclamation-triangle'),
  edit: require('react-icons/lib/fa/pencil'),
  editSquare: require('react-icons/lib/fa/edit'),
  delete: require('react-icons/lib/fa/trash-o'),
  copy: require('react-icons/lib/fa/copy'),
  list: require('react-icons/lib/fa/list-ul'),
  add: require('react-icons/lib/fa/plus-circle'),
  plus: require('react-icons/lib/fa/plus'),
  success: require('react-icons/lib/fa/check-circle'),
  cancel: require('react-icons/lib/fa/ban'),
  check: require('react-icons/lib/fa/check'),
  caretUp: require('react-icons/lib/fa/caret-up'),
  caretDown: require('react-icons/lib/fa/caret-down'),
  caretLeft: require('react-icons/lib/fa/caret-left'),
  caretRight: require('react-icons/lib/fa/caret-right'),
  minus: require('react-icons/lib/fa/minus'),
  infoCircle: require('react-icons/lib/fa/info-circle'),
  link: require('react-icons/lib/fa/chain'),
  ellipsis: require('react-icons/lib/fa/ellipsis-h'),
  ellipsisV: require('react-icons/lib/fa/ellipsis-v'),
  refresh: require('react-icons/lib/fa/refresh'),
  mobile: require('react-icons/lib/fa/mobile'),
  circle: require('react-icons/lib/fa/circle'),
  arrowLeft: require('react-icons/lib/fa/arrow-left'),
  shareAlt: require('react-icons/lib/fa/share-alt'),
  filter: require('react-icons/lib/fa/filter'),
  chevronLeft: require('react-icons/lib/fa/chevron-left'),
  chevronRight: require('react-icons/lib/fa/chevron-right'),
  chevronUp: require('react-icons/lib/fa/chevron-up'),
  chevronDown: require('react-icons/lib/fa/chevron-down'),
  user: require('react-icons/lib/fa/user'),
  folderOpen: require('react-icons/lib/fa/folder-open'),
  star: require('react-icons/lib/fa/star'),
  image: require('react-icons/lib/fa/image'),
  download: require('react-icons/lib/fa/download'),
  clock: require('react-icons/lib/fa/clock-o'),
  questionCircle: require('react-icons/lib/fa/question-circle'),
  calendarX: require('react-icons/lib/fa/calendar-times-o'),
  calendar: require('react-icons/lib/fa/calendar'),
  bars: require('react-icons/lib/fa/bars'),
  comments: require('react-icons/lib/fa/comments'),
  comment: require('react-icons/lib/fa/comment'),
  envelope: require('react-icons/lib/fa/envelope'),
  bell: require('react-icons/lib/fa/bell'),
  bellO: require('react-icons/lib/fa/bell-o'),
  gift: require('react-icons/lib/fa/gift'),
  cog: require('react-icons/lib/fa/cog'),
  globe: require('react-icons/lib/fa/globe'),
  facebook: require('react-icons/lib/fa/facebook'),
  twitter: require('react-icons/lib/fa/twitter'),
  linkedIn: require('react-icons/lib/fa/linkedin'),
  rocket: require('react-icons/lib/fa/rocket'),
  lock: require('react-icons/lib/fa/lock'),
  checkLight: require('react-icons/lib/io/checkmark'),
  coffee: require('react-icons/lib/fa/coffee'),
  angleDown: require('react-icons/lib/fa/angle-down'),
  externalLink: require('react-icons/lib/fa/external-link'),
  bolt: require('react-icons/lib/fa/bolt'),
  eyeSLash: require('react-icons/lib/fa/eye-slash'),
  fog: FogIcon,
  storm: ThunderstormIcon,
  snow: CloudSnowIcon,
  rain: CloudRainIcon,
  sunny: SunIcon,
  overcast: CloudSunIcon,
  questionCircleLiteProIcon: QuestionCircleLiteProIcon,
  envelopePro: EnvelopeProIcon,
  clone: SolidCloneProIcon,
  mobilePro: MobileProIcon,
  fileAlt: FileAltIcon,
  lockOutline: LockAltLightOutlineIcon,
  unlockOutline: UnlockLightOutlineIcon,
  commentOutline: CommentOutlineIcon,
  phoneOutline: PhoneAltOutlineIcon,
  boltOutline: BoltOutlineIcon,
  wrench: WrenchIcon,
  chevronRightSlim: ChevronRightSlim,
  calendarV2: CalendarV2,
  closeCircle: CloseCircle,
  chevronDownSlim: ChevronDownSlim,
  userO: UserO,
  phone: Phone,
  email: Email,
  resume: Resume,
  mapMarker: MapMarker,
  heartAura: HeartAura,
  thumbsDown: ThumbsDown,
  thumbsDownFilled: ThumbsDownFilled,
  thumbsDownSelected: ThumbsDownSelected,
  thumbsUp: ThumbsUp,
  userHeadset: UserHeadsetIcon,
  applicants: Applicants,
  hired: Hired,
  interviews: Interviews,
  approve: Approve,
  decline: Decline,
  editCircle: Edit,
  alert: Bell,
  palmTree: PalmTree,
  timeOff: TimeOff,
  tradeShift: TradeShift,
  availability: Calendar,
  calendarO: CalendarO,
  clockIn: ClockIn,
  clockOut: ClockOut,
  checkFilled: CheckFilled,
  referral: Referral,
  message: Message,
  message2: Message2,
  interview: Interview,
  notes: Notes,
  emptyInterviewState: EmptyInterviewState,
  computerBlue: ComputerBlue,
  linkBlue: LinkBlue,
  mobileBlue: MobileBlue,
  calendarCheck: CalendarCheck,
  envelopeBlue: EnvelopeBlue,
  zipRecruiterLogo: ZipRecruiterLogo,
  jobCardsIllustration: JobCardsIllustration,
  headphones: Headphones,
  restaurant: Restaurant,
  checkCircle: CheckCircle,
  circleCheck: CircleCheck,
  rocketCircle: RocketCircle,
  rocketSolid: RocketSolid,
  eye: Eye,
  notSent: NotSent,
  schedule: Schedule,
  stars: Stars,
  coffee2: Coffee,
  clockOutCircle: ClockOutCircle,
  coffeeBreak: CoffeeBreak,
  coffeeBreakOut: CoffeeBreakOut,
  timeclock: Timeclock,
  mug: Mug,
  martini: Martini,
  cashRegister: CashRegister,
  chefHat: ChefHat,
  forkKnife: ForkKnife,
  contactBook: ContactBook,
  knife: Knife,
  checklist: Checklist,
  bowl: Bowl,
  genericRole: GenericRole,
  addCircle: AddCircle,
  moneyBillTransfer: MoneyBillTransfer,
  userPlus: UserPlus,
  circleAdd: Circle,
  fileExplorer: FileExplorer,
  pen: Pen,
  send: Send,
  editPen: EditPenIcon,
  rocketLaunch: RocketLaunch,
  findCover: FindCover,
  bangLight: BangLight,
  scrollLeft: ScrollLeft,
  scrollRight: ScrollRight,
  clipboardList: ClipboardListIcon,
  chevronLeftLight: ChevronLeftLightIcon,
  xMark: XMark,
  longArrowRight: LongArrowRight,
  plusLight: PlusLight,
};

export const ICON_TYPES = Object.keys(ICON_TYPE_MAP).sort();
export const ICON_SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'];
const DEFAULT_ICON_SIZE = ICON_SIZES[2];
export const ICON_COLORS = ['inherit', ...keys(colors)];
const DEFAULT_ICON_COLOR = ICON_COLORS[0];

const dimensionAsNumber = dim => (isNumber(dim) ? dim : dimensions[dim]);

@cxHelpers('Icon')
class Icon extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(ICON_TYPES).isRequired,
    size: PropTypes.oneOfType([PropTypes.oneOf(ICON_SIZES), PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.oneOf(ICON_SIZES), PropTypes.number]),
    height: PropTypes.oneOfType([
      PropTypes.oneOf(ICON_SIZES),
      PropTypes.number,
    ]),
    color: PropTypes.oneOf(ICON_COLORS),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
  };

  render() {
    const {
      type,
      size = DEFAULT_ICON_SIZE,
      width,
      height,
      color = DEFAULT_ICON_COLOR,
      onClick,
      disabled,
      clickable,
    } = this.props;
    const IconComponent = ICON_TYPE_MAP[type];

    const sizeAsNumber = dimensionAsNumber(size);
    const widthAsNumber = width ? dimensionAsNumber(width) : sizeAsNumber;
    const heightAsNumber = height ? dimensionAsNumber(height) : sizeAsNumber;

    return (
      <IconComponent
        className={this.cx({
          clickable: onClick || clickable,
          disabled,
          [type]: type,
        })}
        color={colors[color]}
        onClick={onClick}
        width={widthAsNumber}
        height={heightAsNumber}
        style={{ width: widthAsNumber, height: heightAsNumber }}
      />
    );
  }
}

export default Icon;
