export enum ButtonDataMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
}

export enum ButtonTarget {
  Blank = '_blank',
  Parent = '_parent',
  Top = '_top',
  Self = '_self',
}
