import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON, postJSON } from 'api/fetch';

import {
  getActiveTipPolicyId,
  getIsTipPoolingExpV4Enabled,
} from 'selectors/session';

import { getTipInsByDayData } from 'features/bottomDrawers/TipPoolDrawer/CalculateTipOutsDrawer/selectors';
import {
  getTipInsUpdateParams,
  populateResponseWithSourceType,
} from 'features/bottomDrawers/TipPoolDrawer/CalculateTipOutsDrawer/TipInsStep/utils';
import { getImportTipsFromPos } from 'features/settings/TipManagerSettingView/selectors';

export const fetchTipInsPOSData = createAsyncThunk(
  'tipIns/fetchTipInsPOSData',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    if (!tipPolicyId || !startDate || !endDate) {
      return;
    }

    try {
      const response = await fetchJSON(
        routes.fetchTipInsPOSData({ tipPolicyId, startDate, endDate })
      );
      return response;
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }
  }
);

export const fetchTipInsEditableData = createAsyncThunk(
  'tipIns/fetchTipInsEditableData',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    if (!tipPolicyId || !startDate || !endDate) {
      return;
    }

    try {
      const response = await fetchJSON(
        routes.fetchTipInsEditableData({ startDate, endDate })
      );
      const transformedResponse = populateResponseWithSourceType(
        state,
        response
      );
      return transformedResponse;
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }
  }
);

export const fetchTipInsData = createAsyncThunk(
  'tipIns/fetchTipInsData',
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    const isTipPoolingExpV4Enabled = getIsTipPoolingExpV4Enabled(state);

    const importTipsFromPOS = getImportTipsFromPos(state);
    if (importTipsFromPOS || !isTipPoolingExpV4Enabled) {
      dispatch(fetchTipInsPOSData({ tipPolicyId, startDate, endDate }));
    }

    if (isTipPoolingExpV4Enabled)
      dispatch(fetchTipInsEditableData({ startDate, endDate }));

    return {
      startDate,
      endDate,
    };
  }
);
export const fetchTipOutsData = createAsyncThunk(
  'tipOuts/fetchTipOutsData',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    if (!tipPolicyId || !startDate || !endDate) {
      return;
    }

    try {
      const response = await postJSON(
        routes.fetchTipOutsData({ tipPolicyId, startDate, endDate })
      );
      return response;
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }
  }
);

export const updateTipsByDay = createAsyncThunk(
  'tipIns/updateTipsByDay',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const params = getTipInsUpdateParams(state, payload);

    try {
      postJSON(routes.updateTipsByDay(), params);
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }

    const tipInsByDay = getTipInsByDayData(state);
    const { rowIndex, columnKey, value } = payload;
    const updatedData = [...tipInsByDay];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [columnKey]: Number(value),
    };
    return updatedData;
  }
);
