import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';

import { TASK_MANAGER_SLICE_NAME } from 'features/taskManager/constants';
import {
  PaginatedTaskResult,
  SingleTask,
} from 'features/taskManager/index.interface';

export const createTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/createTask`,
  async (data: SingleTask): Promise<SingleTask> =>
    postJSON('/task_management/tasks', data)
);

export const fetchTasks = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchTasks`,
  async (): Promise<PaginatedTaskResult> =>
    fetchJSON('/task_management/manager_tasks?page=1&per_page=20')
);
